<!-- NOTE: Lots of responsive formatting with a min screen size (`auth0-sm`) to match Auth0 -->
<template>
  <BareAppWraper>
    <div
      class="auth0-sm:flex auth0-sm:items-center h-full gap-3 justify-center bg-white auth0-sm:bg-nash-purple600"
    >
      <div
        class="min-h-540 w-full auth0-sm:w-100 bg-white flex flex-col items-center gap-3 justify-center rounded-md"
      >
        <img
          class="h-16"
          src="/assets/images/Thyme-Logo-Wordmark-Full.svg"
          alt="Thyme Care"
        />
        <div class="p-6 text-center">
          <h2
            data-test="login-method-header"
            class="font-normal text-xl login-wrapper-font"
          >
            How would you like to sign in to <br />Thyme Care Connect?
          </h2>
        </div>
        <div class="flex-row">
          <TButton
            data-test="login-method-sms"
            label="Sign in with text message"
            @click="() => onLoginMethodClick(LoginMethod.SMS)"
          />
        </div>
        <div class="flex-row">
          <TButton
            data-test="login-method-email"
            label="Sign in with email"
            @click="() => onLoginMethodClick(LoginMethod.EMAIL)"
          />
        </div>
        <div
          data-testid="login-method-playwright"
          class="h-1 w-1"
          @click="() => onLoginMethodClick(LoginMethod.PLAYWRIGHT)"
        ></div>
      </div>
    </div>
  </BareAppWraper>
</template>

<script lang="ts">
import TButton from '@nashville/button/TButton.vue'
import { defineComponent } from 'vue'
import BareAppWraper from '../tokens/BareAppWrapper/BareAppWraper.vue'
import { setup } from './controller'

export default defineComponent({
  components: {
    TButton,
    BareAppWraper,
  },
  setup,
})
</script>

<style lang="scss">
// Don't use scoped here so we can adjust the button styling to match Auth0
.p-button-label {
  @apply font-medium text-base;
}
</style>
<style lang="scss" scoped>
.login-wrapper-font {
  font-family: 'Roboto', sans-serif;
}
</style>
