import { requiredInject } from '@thyme/libs/src/vue/inject'
import { useRouter, useRoute } from 'vue-router'

import { LoginMethod } from '@/types/auth'
import { APP_CONFIG_KEY } from '@/types/config'
/**
 *
 */
export function setup(): {
  onLoginMethodClick: (connection: LoginMethod) => Promise<void>
  LoginMethod: typeof LoginMethod
  showPlaywrightLogin: boolean
} {
  const route = useRoute()
  const router = useRouter()
  const config = requiredInject(APP_CONFIG_KEY)

  /**
   * On login method selection
   * @param connection
   */
  async function onLoginMethodClick(connection: LoginMethod) {
    await router.push({
      path: (route.query.redirect as string) ?? '/',
      query: { ...route.query, auth0Connection: connection },
    })
  }

  return {
    onLoginMethodClick,
    LoginMethod,
    showPlaywrightLogin: config.auth0.enablePasswordAuth,
  }
}
