import { OmniLink } from '@thyme/nashville/src/types/omnilinks'

export const DEFAULT_LOGO_WIDTH = 100

export enum AppTheme {
  PURPLE = 'theme-nash-purple',
  WHITE = 'theme-nash-white',
}

export type Logo = {
  name: string
  url: OmniLink
  src: string
  width?: number
}

export type ThemeConfig = {
  readonly theme: AppTheme
  readonly logos: Logo[]
}

export const TC_PURPLE_LOGO: Logo = {
  name: 'Thyme Care',
  src: '/assets/images/ThymeCare_Logo_Purple.png',
  url: {
    href: 'https://www.thymecare.com/',
  },
  width: DEFAULT_LOGO_WIDTH,
}

export const TC_WHITE_LOGO: Logo = {
  name: 'Thyme Care',
  src: '/assets/images/Thyme-Logo-Wordmark-OffWhite.png',
  url: {
    to: '/',
  },
  width: DEFAULT_LOGO_WIDTH,
}
