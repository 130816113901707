import {
  ThemeConfig,
  AppTheme,
  DEFAULT_LOGO_WIDTH,
  TC_PURPLE_LOGO,
} from '@/types/theme/base'

type ContractingEntityThemeConfig = {
  readonly [key: string]: ThemeConfig
}

export const CONTRACTING_ENTITIES_THEME_CONFIGS: ContractingEntityThemeConfig =
  {
    amerihealth_new_jersey: {
      theme: AppTheme.WHITE,
      logos: [
        TC_PURPLE_LOGO,
        {
          name: 'AmeriHealth New Jersey',
          src: '/assets/images/logos/ahnj.png',
          url: {
            href: 'https://www.amerihealthnj.com/',
          },
          width: DEFAULT_LOGO_WIDTH,
        },
      ],
    },
    clover_health: {
      theme: AppTheme.WHITE,
      logos: [
        TC_PURPLE_LOGO,
        {
          name: 'Clover Health',
          src: '/assets/images/logos/clover.png',
          url: {
            href: 'https://www.cloverhealth.com/',
          },
          width: DEFAULT_LOGO_WIDTH,
        },
      ],
    },
    oak_street: {
      theme: AppTheme.WHITE,
      logos: [
        TC_PURPLE_LOGO,
        {
          name: 'Oak Street Health',
          src: '/assets/images/logos/osh.png',
          url: {
            href: 'https://www.oakstreethealth.com/',
          },
          width: DEFAULT_LOGO_WIDTH,
        },
      ],
    },
  } as const
