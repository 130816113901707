import { CONTRACTING_ENTITIES_THEME_CONFIGS } from '../contractingEntities'
import {
  ALL_PRACTICES_THEME_CONFIGS,
  FLAGGED_PRACTICE_THEME_CONFIG,
} from '../practices'

export enum DefaultTheme {
  DEFAULT = 'default',
  AON = 'tcAonFallback',
}

export enum RouteThemeQueryParam {
  PRACTICE = 'practice',
  CONTRACTING_ENTITY = 'ce',
}

export type RouteThemeDefinition = {
  themeQueryParams: RouteThemeQueryParam[] // these should be in priority order
  default: DefaultTheme
}

export const ALL_THEME_CONFIGS = {
  ...ALL_PRACTICES_THEME_CONFIGS,
}

/**
 * CLEANUP(SPC-1597) - Remove practice logo feature flag
 * when cleaning up, just add CONTRACTING_ENTITIES_THEME_CONFIGS to the ALL_THEME_CONFIGS object and remove this
 */
export const FLAGGED_THEME_CONFIGS = {
  ...ALL_THEME_CONFIGS,
  ...FLAGGED_PRACTICE_THEME_CONFIG,
  ...CONTRACTING_ENTITIES_THEME_CONFIGS,
}
