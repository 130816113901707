import { AllowedIcons } from '@thyme/nashville/src/types/icons'
import type { LinkedMobileButtonAttrs } from '@/components/tokens/LinkedMobileButton/types'
import { sendLoggedInMonitoringEvent } from '@/libs/events'
import { useLoggedInEntityId } from '@/libs/state/auth0Client'
import {
  THYME_PHONE_NUMBER,
  THYME_PHONE_NUMBER_DISPLAY,
} from '@/libs/thymeInfo'
import { MonitoringEventName } from '@/types/events'

type ContactButtonAttrs = LinkedMobileButtonAttrs & {
  method: string
}
/**
 *
 */
export function setup(): {
  buttons: ContactButtonAttrs[]
  onContactButtonClick: (link: string) => void
  AllowedIcons: typeof AllowedIcons
} {
  const entityId = useLoggedInEntityId()

  /**
   * event track contact button click
   * @param method
   */
  function onContactButtonClick(method: string) {
    sendLoggedInMonitoringEvent(
      {
        event: MonitoringEventName.CONTACT_BUTTON_CLICK,
        data: { method },
      },
      entityId
    )
  }

  return {
    AllowedIcons,
    onContactButtonClick,
    buttons: [
      {
        color: 'nash-purple600',
        icon: AllowedIcons.textMessage,
        title: 'Text Thyme Care',
        description: `Text your Care team at ${THYME_PHONE_NUMBER_DISPLAY} with questions or to set up a time to talk. We typically respond to texts within one business day.`,
        link: { href: `sms:${THYME_PHONE_NUMBER}` },
        method: 'sms',
      },
      {
        color: 'nash-purple500',
        icon: AllowedIcons.phone,
        title: 'Call Thyme Care',
        description: `For 24/7 support, call ${THYME_PHONE_NUMBER_DISPLAY} to reach a Thyme Care nurse or clinician.`,
        link: { href: `tel:${THYME_PHONE_NUMBER}` },
        method: 'call',
      },
    ],
  }
}
