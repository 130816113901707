<!-- The most basic wrapper that just makes the viewbox take up the full screen-->
<template>
  <div class="app-wrapper">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({})
</script>

<style lang="scss" scoped>
.app-wrapper {
  @apply w-screen;
  @apply flex flex-col justify-items-stretch;
  // Old Browsers & Firefox
  height: 100vh;
  // Modern Browsers & Mobile
  // Fixes browser height issue on mobile
  // https://stackoverflow.com/a/70048720
  height: 100dvh;
}
</style>
