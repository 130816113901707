import { ALL_THEME_CONFIGS, DefaultTheme } from '@/types/theme'
import { Logo } from '@/types/theme/base'
import type { PropType } from 'vue'

export const NAV_BAR_PROPS = {
  logos: {
    type: Array as PropType<Logo[]>,
    default: ALL_THEME_CONFIGS[DefaultTheme.DEFAULT],
  },
  additionalClasses: {
    type: Array,
    default: () => [],
  },
} as const
