<template>
  <NavPage
    :theme-config="themeConfig"
    :class="themeConfig.theme"
    :use-content-padding="!isValidPage"
    :limit-max-width="!isValidPage"
    :show-authed-pages="false"
  >
    <EmbeddedForm
      v-if="isValidPage"
      :account-id="accountId"
      :flow-label="flowLabel"
      :variant-label="variantLabel"
      :embedded-config="embeddedConfig"
      :query-params="queryParams"
      data-testid="survey-form"
    />
    <section v-else>
      <h2 id="not-found-header" data-testid="not-found-header">
        This page does not exist.
      </h2>
      <p id="not-found-body" data-testid="not-found-body">
        If you received a link via text, click on it again. If you typed the URL
        directly into your browser, make sure it is spelled correctly.
      </p>
    </section>
  </NavPage>
</template>

<script lang="ts">
import EmbeddedForm from '@nashville/EmbeddedForm/EmbeddedForm.vue'
import { defineComponent } from 'vue'
import NavPage from '@/components/tokens/NavPage/NavPage.vue'
import { setup } from './controller'

export default defineComponent({
  components: {
    EmbeddedForm,
    NavPage,
  },
  setup,
})
</script>

<style lang="scss" scoped>
.theme-nash-white {
  :deep(#not-found-header) {
    font-family: 'DM Sans', Helvetica, Arial, sans-serif;
    color: #006496;
  }

  :deep(#not-found-body) {
    color: #534e5e;
  }

  :deep(#logout) {
    color: #006496;
  }

  :deep(#top-nav) {
    @apply bg-nash-neutral000;
    color: #006496;
  }
}
</style>
