<template>
  <nav
    id="top-nav"
    class="nav-bar-wrapper nash-drop-shadow"
    :class="additionalClasses"
  >
    <div class="flex">
      <div v-for="logo in logos" :key="logo.name">
        <TOmniLink :link="logo.url" :aria-label="logo.name">
          <img :width="logo.width" :src="logo.src" :alt="logo.name" />
        </TOmniLink>
      </div>
    </div>
    <LogoutButton />
  </nav>
</template>

<script lang="ts">
import TOmniLink from '@nashville/omnilink/TOmniLink.vue'
import { defineComponent } from 'vue'
import LogoutButton from '@/components/tokens/LogoutButton/LogoutButton.vue'
import { NAV_BAR_PROPS } from './types'

export default defineComponent({
  components: {
    LogoutButton,
    TOmniLink,
  },
  props: NAV_BAR_PROPS,
})
</script>

<style lang="scss" scoped>
.nav-bar-wrapper {
  @apply flex grow-0 shrink-0;
  @apply justify-between items-center;
  @apply w-full;
  @apply px-6 py-3;
  @apply z-10;
}
</style>
