import { createAuthGuard, useAuth0 } from '@auth0/auth0-vue'
import { storeToRefs } from 'pinia'
import { unref } from 'vue'
import { RouteLocationNormalized } from 'vue-router'
import { sendLoggedInMonitoringEvent } from '@/libs/events'
import { useFlagStore } from '@/libs/flags'
import { getLogger } from '@/libs/logging'
import { useLoggedInEntityId } from '@/libs/state/auth0Client'
import { LoginMethod } from '@/types/auth'
import { MonitoringEventName } from '@/types/events'

export const AUTH_CALLBACK_NAME = '#authCallback'

/**
 *
 * @param to
 */
function sendTrackingEvent(to: RouteLocationNormalized) {
  try {
    const entityId = useLoggedInEntityId()
    sendLoggedInMonitoringEvent(
      { event: MonitoringEventName.LOGIN, data: { target: to.fullPath } },
      entityId
    )
  } catch (e: any) {
    getLogger().error('Error sending monitoring event', e)
  }
}

/**
 * Check if the user has already selected a login method, and if so, direct to Auth0.
 * If not, direct to login method page.
 * @param to
 * @param from
 */
async function handleLoginFlow(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) {
  const { enablePhoneLogin } = storeToRefs(useFlagStore())

  const SPLIT_LOAD_ATTEMPTS = 4
  const SPLIT_LOAD_ATTEMPT_DELAY_MS = 500

  // IMPROVEME (SPC-1559): Remove this
  // Wait up to 2 seconds for the flags to load to know whether
  // to show the phone login option
  for (let i = 0; i < SPLIT_LOAD_ATTEMPTS; i++) {
    if (useFlagStore().isLoaded) {
      break
    }
    await new Promise((resolve) =>
      setTimeout(resolve, SPLIT_LOAD_ATTEMPT_DELAY_MS)
    )
  }

  let connection = to.query.auth0Connection
  if (!enablePhoneLogin.value) {
    connection = LoginMethod.EMAIL
  }
  const loginMethodPageName = '#loginMethod'
  if (
    (from.name === loginMethodPageName || !enablePhoneLogin.value) &&
    // Check it is a valid login method
    Object.values(LoginMethod).includes(connection as LoginMethod)
  ) {
    // Call the auth guard, which will redirect to the Auth0 login page if necessary
    const authGuard = createAuthGuard({
      redirectLoginOptions: {
        authorizationParams: { connection: connection as LoginMethod },
      },
    })
    await authGuard(to)
    return true
  }
  return {
    name: loginMethodPageName,
    query: { ...to.query, redirect: to.fullPath },
  }
}
/**
 * Add an auth guard unless the route is marked as publicly accessible
 * @param to
 * @param from
 */
export async function maybeAuthGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) {
  if (to.meta.noAuthRequired) {
    return true
  }
  const client = useAuth0()
  if (!unref(client.isAuthenticated)) {
    // Try to silently auth if we can
    await client.checkSession()
  }
  if (from.name === AUTH_CALLBACK_NAME) {
    sendTrackingEvent(to)
  }
  if (unref(client.isAuthenticated)) {
    return true
  }
  return handleLoginFlow(to, from)
}
