import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { LocationQuery, useRoute } from 'vue-router'
import { useFlagStore } from '@/libs/flags'
import {
  DEFAULT_EMBEDDED_CONFIG,
  FORMSORT_ACCOUNT_ID,
  defaultQueryParams,
} from '@/libs/formsort'
import { getLogger } from '@/libs/logging'
import {
  ALL_THEME_CONFIGS,
  DefaultTheme,
  FLAGGED_THEME_CONFIGS,
  RouteThemeDefinition,
  RouteThemeQueryParam,
} from '@/types/theme'
import { ThemeConfig } from '@/types/theme/base'
import type { Ref } from 'vue'

/**
 * Decode form arguments from base64 url encoded string
 * @param encodedArgs base64 url encoded string
 */
export function decodeFormArgs(
  encodedArgs: string | undefined
): URLSearchParams {
  if (!encodedArgs) return new URLSearchParams('')
  try {
    const decoded = window.atob(decodeURIComponent(encodedArgs))
    return new URLSearchParams(decoded)
  } catch (e) {
    getLogger().error('unable to decode form_args', String(e))
    return new URLSearchParams('')
  }
}

/**
 * Get the theme configuration based on the provided theme definition and query parameters.
 * @param themeDefinition - The theme definition object.
 * @param queryParams - The query parameters from the URL.
 */
export function useThemeConfigFromRoute(
  themeDefinition?: RouteThemeDefinition,
  queryParams?: LocationQuery
): ThemeConfig {
  // CLEANUP(SPC-1597) - Remove practice logo feature flag
  // just use ALL_THEME_CONFIGS when cleaning up
  const { showHeaderPracticeLogo } = storeToRefs(useFlagStore())
  const themeConfig = showHeaderPracticeLogo.value
    ? FLAGGED_THEME_CONFIGS
    : ALL_THEME_CONFIGS

  const defaultTheme = themeDefinition?.default ?? DefaultTheme.DEFAULT
  if (!themeDefinition?.themeQueryParams) {
    return themeConfig[defaultTheme]
  }

  for (const param of themeDefinition.themeQueryParams) {
    let value = queryParams?.[param] as string
    if (!value) continue

    if (param === RouteThemeQueryParam.CONTRACTING_ENTITY) {
      value = value.replace(/\//g, '')
    }

    if (themeConfig[value]) {
      return themeConfig[value]
    }
  }

  return themeConfig[defaultTheme]
}

/**
 *
 */
export function setup(): {
  accountId: string
  flowLabel: string | null
  variantLabel: string | null
  embeddedConfig: object
  isValidPage: boolean
  queryParams: Array<[string, string]>
  themeConfig: Ref<ThemeConfig>
} {
  const route = useRoute()

  /**
   * Decode arguments then extract flow_id and variant
   * Pass to Component as props
   * @param encodedArgs
   */
  function splitFormArgs(encodedArgs: string | undefined) {
    const urlParamEntries = decodeFormArgs(encodedArgs)
    const flowLabel = urlParamEntries.get('flow_id')
    urlParamEntries.delete('flow_id')
    const variantLabel = urlParamEntries.get('variant')
    urlParamEntries.delete('variant')
    if (!flowLabel || !variantLabel) {
      getLogger().error(
        'flow_id or variant not found in form_args.  Cannot load form'
      )
    }
    return {
      flowLabel,
      variantLabel,
      queryParams: [
        ...defaultQueryParams(),
        ...Array.from(urlParamEntries.entries()),
      ],
    }
  }

  const formArgs = splitFormArgs(route.query.form_args as string | undefined)
  const isValidPage = !!(formArgs.flowLabel && formArgs.variantLabel)

  const themeDefinition = route?.meta?.themeDefinition as RouteThemeDefinition
  const themeConfig = computed(() =>
    useThemeConfigFromRoute(themeDefinition, route?.query)
  )

  return {
    accountId: FORMSORT_ACCOUNT_ID,
    embeddedConfig: DEFAULT_EMBEDDED_CONFIG,
    isValidPage,
    ...formArgs,
    themeConfig,
  }
}
