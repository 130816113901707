<template>
  <BareAppWraper>
    <NavBar :logos="themeConfig.logos" :additional-classes="headerClasses" />
    <div id="content-wrapper">
      <main class="justify-center flex" :class="mainPaddingClasses">
        <div :class="limitedWidthClasses">
          <slot></slot>
        </div>
      </main>
      <NavFooter :show-authed-pages="showAuthedPages" />
    </div>
  </BareAppWraper>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BareAppWraper from '@/components/tokens/BareAppWrapper/BareAppWraper.vue'
import NavBar from '@/components/tokens/NavBar/NavBar.vue'
import NavFooter from '@/components/tokens/NavFooter/NavFooter.vue'
import { setup } from './controller'
import { NAV_PAGE_PROPS } from './types'

export default defineComponent({
  components: {
    BareAppWraper,
    NavBar,
    NavFooter,
  },
  props: NAV_PAGE_PROPS,
  setup,
})
</script>

<style lang="scss" scoped>
#content-wrapper {
  @apply space-y-6;
  @apply overflow-auto grow;
}

.content-padding {
  @apply px-6 py-9;
}
</style>
